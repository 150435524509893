import React, {useEffect, lazy} from "react";
import store from "../store";
import allActions from "../services/actionsArray";
import shopApi from "../api/shop";
import generalAction from "../api/generalAction";
import {MetaWrapper} from "../components/MetaWrapper";

const mongoose = require("mongoose");
import dynamic from 'next/dynamic'
import {IProps} from "../types/homePageTypes";
import {GetServerSideProps, NextPage} from "next";
import {setMetaTags} from "../store/general";

const HomePageOne = dynamic(() => import("../components/home/HomePageOne"))

const Home: NextPage<IProps> = (props) => {

    const {
        locale,
        sliderData,
        newProducts,
        featuredProducts,
        bestSellerProducts,
        metas,
        dbName,
        dispatches: dispatchesNew,
        currency,
        domain,
        rate,
        getBuilderData,
    } = props;
    //@ts-ignore

    const metaTags = Object.keys(metas?.meta).length ? JSON.parse(metas.meta[0].translations.find(el => el.locale === locale).home_seo) : [];
    const {dispatch} = store;

    const firstLoad = true;

    useEffect(() => {
        ////FIXME
        ////allActions[actionKey](dispatchesNew[actionKey])
        for (let actionKey in dispatchesNew) {
            const vvvvv: any = allActions
            const ads: any = vvvvv[actionKey]
            const zzz: any = dispatchesNew
            const cccc: any = zzz[actionKey];
            const bss: any = ads(cccc)
            dispatch(bss);
        }
        dispatch(setMetaTags(metaTags))
    }, [locale, rate]);


    return sliderData && (
        <MetaWrapper
            dbName={dbName}
            title={`${metaTags?.meta_title}`}
            m_title={metaTags?.meta_title || dbName}
            m_desc={metaTags?.meta_description || dbName}
            m_key={metaTags?.meta_keywords || dbName}
            m_img={`pr1.webp`}
            m_url={`https://${dbName}/`}
        >
            <HomePageOne
                locale={locale}
                currency={currency}
                headerLayout="default"
                newProducts={newProducts || []}
                featuredProducts={featuredProducts || []}
                bestSellerProducts={bestSellerProducts || []}
                metas={metas}
                getBuilderData={getBuilderData}
                firstLoad={firstLoad}
                dbName={dbName}
                rate={rate}
                domain={domain}
                sliderData={sliderData}
            />
        </MetaWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async ({locale, locales, req, res}: any) => {
    const dbName = req.headers["x-forwarded-host"];
    var selectedCurrency: string = '';
    var selectedRate;
    res.setHeader(
        'Cache-Control',
        'public, s-maxage=10, stale-while-revalidate=59'
    )
    ////CHECKING CURRENCY
    if (Object.keys(req.query).length > 0) {
        selectedCurrency = req.query.currencies;
    }
    let domain: string = dbName.split(".")[0] === "www" ? dbName.split(".")[1] : dbName.split(".")[0]

    var defaultLocaleSelected, currency, rate: any, generalDispatches: any;

    await generalAction
        .action("general", {
            locale, selectedCurrency, dbName
        })
        .then((res: any) => {
            defaultLocaleSelected = res.locale;
            currency = res.currency;
            rate = res.rate;
            generalDispatches = res.dispatches;
        });

    ////GETTING RATE FOR CURRENCY
    if (Object.keys(req.query).length > 0) {
        selectedRate = rate.currencies_new.find((item: any) => item.code == selectedCurrency);
    }
    var selectedLocale = locale != "catchAll" ? locale : defaultLocaleSelected;

    selectedLocale = 'en'
    /*********** new request async ********/
    const options = {
        locale: defaultLocaleSelected,
        currency: selectedCurrency,
        rate: selectedRate?.exchange_rate?.rate || 1,
        limit: 8
    }
    const metaTags = await shopApi.getHomeMetaAsync(domain, defaultLocaleSelected);
    const homeFetProd = await shopApi.getHomeProductsAsync(options, domain)
    const sliderData = await shopApi.sliderData(domain)
    const getBuilderData = await shopApi.getBuilderDataAsync(2, dbName)

    const dispatches: any = {
        ...generalDispatches.clientSide,
        ...generalDispatches.serverSide,
    };

    mongoose.connection.close();

    return {
        props: {
            locale: defaultLocaleSelected,
            currency: currency,
            dbName,
            sliderData: sliderData,
            rate: selectedRate?.exchange_rate?.rate || 1,
            metas: metaTags,
            featuredProducts: JSON.parse(JSON.stringify(homeFetProd.featuredProducts)),
            newProducts: JSON.parse(JSON.stringify(homeFetProd.newProduct)),
            bestSellerProducts: JSON.parse(JSON.stringify(homeFetProd.bestSellers)),
            dispatches,
            getBuilderData: getBuilderData[0] || {},
            domain: domain,
        },
    };

}

export default Home;
